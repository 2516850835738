main {
  min-height: 80vh;
}

.rating span {
  margin: 0.1rem;
}

h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.3rem;
  padding: 0.5rem 0;
}

.image-selector-left{
  position: absolute;
    top: 50%;
    z-index: 10;
    color: black;
    cursor: pointer;
    user-select: none;
    left: 10px;
}

.image-selector-right{
  position: absolute;
    top: 50%;
    z-index: 10;
    color: black;
    cursor: pointer;
    user-select: none;
    right: 10px;
}

.media-library-selected{
  border: 6px solid;
}

.toolbarClassName, .toolbarClassName a{
  color: black !important;
}

.editorClassName{
  background-color: white;
  color: black !important;
  min-height: 200px;
}

.rdw-embedded-wrapper{
  display: none !important;
}

.rdw-image-wrapper{
  display: none !important;
}

.rdw-link-wrapper{
  display: none !important;
}

.rdw-remove-wrapper{
  display: none !important;
}

.rdw-history-dropdown{
  display: none !important;
}

.rdw-fontsize-wrapper{
  display: none !important;
}

.rdw-fontfamily-wrapper{
  display: none !important;
}

.rdw-colorpicker-wrapper{
  display: none!important;
}
.rdw-text-align-wrapper{
  display: none!important;
}

.editorClassName h1, .editorClassName h2, .editorClassName h3,  .editorClassName h4,.editorClassName h5,.editorClassName h6 {
  color: black !important;
}

.rdw-editor-main {
  border: 1px solid #F1F1F1;
}

.footerBar div, .footerBar h5, .footerBar a{
  color: rgba(255,255,255,0.5);
}

.navbar {
  background-color: #0C2340;
  color: white;
}

.navbar-light .navbar-nav .nav-link{
  color: white;
}

footer{
  background-color: #0C2340;
  color: white;
}

.footerBar div, .footerBar h5, .footerBar a{
  color: white;
}

.footerPhone{
  list-style: none;
  padding: 0px;
}

.navbar-light .navbar-brand {
  color: white;
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: white;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: white;
}

.navbar-light .navbar-nav .nav-link.active{
  color: white;
}